.getPaid {
    height: 500px;
    width: 770px;
    background-color: white;
    margin-top: 50px;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.getPaid h6 {
    text-align: center;
}

.getPaid Button {
    margin-top: 30px;
}