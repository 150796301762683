.course-content-container {
  padding: 2rem;
  height: 20rem;
  background-color: #fff;
  box-shadow: -3px 3px 14px -2px rgba(0, 0, 0, 0.30);
}
.course-content-container::-webkit-scrollbar {
  width: 12px;
}
.course-content-container::-webkit-scrollbar-track {
  border-right: 0.5px solid #a3a2a2;
  border-left: 0.5px solid #a3a2a2;
  background: #f6f6f6;
}
.course-content-container::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #a3a2a2;
}
