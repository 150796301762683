.container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  overflow: auto;
}

.grid {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: auto;
  /* -moz-column-gap: 20px;
  column-gap: 20px; */
  /* -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
  -moz-column-fill: balance;
  column-fill: balance; */
  width: 1300px;
  min-height: 100vh;
  
}

/**
display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
*/

.box {
  display: inline-block;
  padding-bottom: 30px;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
  width: 410px;
  margin: 10px;
}


@media screen and (max-width: 1400px) {
  .grid {
    /* -moz-column-gap: 10px;
    column-gap: 10px; */
    width: 1220px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  .box {
    width: 365px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .grid {
    /* -moz-column-gap: 20px;
    column-gap: 20px;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2; */
    width: 900px;
  }
  .box {
    width: 430px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .grid {
    /* -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2; */
    width: 800px;
    display: flex;
    flex-direction: column;
    padding-left: 35px;
  }
  .box {
    width: 340px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .grid {
    /* -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1; */
    width: 580px;
  }
  .box {
    padding-bottom: 30px;
    width: 480px;
  }
}

@media screen and (max-width: 520px) {
  .grid {
    width: 420px;
    display: flex;
    align-content: center;
    padding-left: 25px;
  }
  .box {
    padding-bottom: 25px;
    width: 350px;
  }
}
