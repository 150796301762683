.shimmer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    overflow: auto;
}

.shimmer{
    height: fit-content;
    width: 1300px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: auto;
}

.shimmer-fb-card{
      width: 410px;
}
.shimmer-card {
    width: 410px;
    height: 320px;
    padding: 10px;
    display: flex;
    justify-items: center;
    margin: 10px;
}

.shimmer-fb-card {
    width: 410px;
    height: 550px;
    padding: 10px;
    display: flex;
    justify-items: center;
    margin: 10px;
}

.shimmer-card-url-btn{
    margin-top: 1rem;
    width: 98%;
    height: 25px;
    display: flex;
    justify-content: space-between;
    margin-left: 0.3rem;
}

.shimmer-card-url-btn > *{
    background-color: #d8d8d8;
    width: 35%;
    height: 30px;
    transition: #d8d8d8 0.5s ease;
    animation: pulse 1s infinite;
}

.shimmer-card-title{
    background-color: #d8d8d8;
    width: 98%;
    height: 25px;
    margin-top: 1rem;
    margin-left: 0.3rem;
    transition: #d8d8d8 0.5s ease;
    animation: pulse 1s infinite;
}

.shimmer-card-description{
    margin-top: 1rem;
}

.shimmer-card-description > p{
    background-color: #d8d8d8;
    width: 98%;
    height: 10px;
    margin-left: 0.3rem;
    transition: #d8d8d8 0.5s ease;
    animation: pulse 1s infinite;
}

.shimmer-card-links{
    width: 98%;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    margin-left: 0.3rem;
    justify-content: space-between;
}

.shimmer-card-links > p{
    background-color: #d8d8d8;
    width: 35%;
    height: 25px;
    transition: #d8d8d8 0.5s ease;
    animation: pulse 1s infinite;
}

.shimmer-card-image{
    background-color: #d8d8d8;
    width: 98%;
    margin-left: 0.3rem;
    height: 310px;
    transition: #d8d8d8 0.5s ease;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
      background-color: #d8d8d8;
    }
    50% {
      background-color: #bdbdbd;
    }
    100% {
      background-color: #d8d8d8;
    }
  }

  
@media screen and (max-width: 1400px) {
  .shimmer {
    width: 1220px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  .shimmer-fb-card{
    width: 365px;
  }
  .shimmer-card {
    width: 365px;
    /* padding-bottom: 15px; */
  }
}

@media screen and (max-width: 1200px) {
  .shimmer {
    width: 900px;
  }
  .shimmer-fb-card{
    width: 430px;
  }
  .shimmer-card {
    width: 430px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .shimmer {
    width: 800px;
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    
  }
  .shimmer-fb-card{
    width: 340px;
  }
  .shimmer-card {
    width: 340px;
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .shimmer {
    width: 580px;
  }
  .shimmer-fb-card{
    width: 480px;
  }
  .shimmer-card {
    width: 480px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 520px) {
  .shimmer {
    width: 420px;
    display: flex;
    align-content: center;
    padding-left: 15px;
  }
  .shimmer-fb-card{
    width: 320px;
  }
  .shimmer-card {
    width: 320px;
    padding-bottom: 25px;
  }
}
