/* Input Range Slider */ 

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    /* opacity: 0.8; */
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
.contribution-slider{
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  /* opacity: 0.8; */
  -webkit-transition: .2s;
  transition: opacity .2s;
}

  .slider:hover {
    opacity: 1;
  }  
  
  .slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 25px;
    cursor: pointer;
    /* background-color: #366EA5; */
    background-color: transparent;
    background-image: url("/src/assets/profile-images/slider_pen.svg");
    object-fit: cover;
    border-radius: 0px;
    background-size: 24px;
    background-position: bottom;
    width: 15px;
    z-index: 1;
    position: relative;
    border: none;
  }
  .slider0::-moz-range-thumb {
    transform: translateX(-8px);
  }
  
  .slider1::-moz-range-thumb {
    transform: translateY(-6px) translateX(-8px);
  } 

  input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.slider-bg-limiter{
  display: inline-block;
  position: absolute;
  height: 25px;
  width: 16px;
  background: rgb(249, 254, 255);
  right: 0;
  border-left: 2px solid rgb(176, 208, 240);
}
.slider-thumb {
  display: none;
}

@-moz-document url-prefix() {

}
  
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 25px;
    cursor: pointer;
    background-color: unset;
    background-image: url("/src/assets/profile-images/slider_pen.svg");
    object-fit: cover;
    background-size: 24px;
    background-position: bottom;
    width: 15px;
    z-index: 1;
    position: relative;
  }
  
  .slider0::-webkit-slider-thumb {
    transform: translateX(-8px);
  }
  .slider1::-webkit-slider-thumb {
    transform: translateY(-6px) translateX(-8px);
  }

  /* .contribution-slider::-webkit-slider-thumb {
    margin-bottom: 12px;
  } */
  
 
  .slider-div{
    position: absolute;
    width: 1px;
    height: 25px;
    border-left: 2px dashed rgb(170, 170, 204);
    opacity: 0.5;
    /* background: rgb(170, 170, 204); */
  }
  
  .sd1{
    left:25%;
  }
  .sd2{
    left:50%;
  }
  .sd3{
    left:74%;
  }
  .timebar-input{
    border: 2px solid #d9d1d1;
    border-radius: 5px;
  }
  .label-text{
    font-size: 12px;
  }
  

  .noslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 23px;
    height: 24px;
    border: 0;
    background: none;
    background-image: none;
    cursor: pointer;
  }
  
  .noslider::-moz-range-thumb {
    width: 23px;
    height: 25px;
    border: 0;
    background: none;
    background-image: none;
    cursor: pointer;
  }

  .heading-bullet{
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 2px solid #C3E1FF;
    border-radius: 50%;
    background-color: white;
    margin-left: -49px;
    position: absolute;
    margin-top: 10px;
  }

.delete-btn-work{
  background: #B01314;
  padding: 6px 12px;
  color: white;
  border-radius: 5px;
  padding-left: 0px;
  position: absolute;
    right: 24px;
    cursor: pointer;
}

.skills-input-value{
  width: 42px;
  border: none;
  background: transparent;
  font-size: 14px;
  z-index: 0;
  text-align: right;
  margin-bottom: 4px;
}

.skills-input-value::-webkit-inner-spin-button{
  display: none
}
.skill-timebar {
  transition: all 0.5s ease;
}
.askconfirmation-modal{
  width: 70%;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  box-shadow: gray 1px 1px 6px;
}
.skill-value-type{
  padding-top: 3px;
}

@media (max-width: 767px) {
.edit-timebar-section{
  margin: 0px 14px;
  font-size: 12px;
}
.skills-input-value{
  font-size: 12px;
}
.askconfirmation-modal{
  top: 25%;
  left: 5%;
  transform: translate(0%, 0%) !important;
}
.contribution-label{
  width:100%;
  text-align: center;
}
.add-skill-btn{
  text-align: center;
  margin-left: 10%;
  margin-top: 6px;
}
.slider-thumb{
  left: -5px;
}
.skill-bar-options{
  max-height: 30px;
  padding-left: 0px;
  margin-left: -6px;
}
.skill-value-type{
  padding-top: 3px;
}
.skill-delete-option{
  padding-top: 4.4px;
  padding-left: 4px;
}
.skill-save-option{
  width: 16px;
  padding-left: 5px;
  padding-top: 2px;
}
.delete-btn-work{
  padding: 6px 0px;;
}
.load-more-exp-btn{
  font-size: 14px;
}
}