.removeAdButtons {
  width: 130px;
  height: 40px;
  border-radius: 8px;
  border-style: none;
}

.confirmationDialogBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.confirmationDialogBox_buttonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.confirmationDialogBox_buttonsContainer_buttons {
  width: 100px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px;
  background-color: white !important;
  border-color: var(--bs-border-color-translucent);
}

.confirmationDialogBox_buttonsContainer_buttons:hover {
  background-color: lightgrey !important;
}
