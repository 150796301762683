.card-body {
  font-family: Arial;
}

.headline,
.callout {
  color: #1a0dab;
}

.headline {
  font-size: 20px;
  text-decoration: none;
}

.headline:hover {
  text-decoration: underline;
  color: #1a0dab;
}

.ad-description,
.callout,
.ad-indicator {
  font-size: 14px;
}

.callout {
  line-height: 1.2;
}

.ad-indicator,
.ad-indicator > a {
  color: rgb(32, 33, 36);
}

.ad-indicator > span {
  font-size: 15px;
}

.ad-card .hide-or-show {
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.ad-card:hover .hide-or-show {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
