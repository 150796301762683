.search-ads-container {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.55)
    ),
    url("../../assets/images/dummy_search-ads-background.jpg");
  background-size: cover;
  background-position: center;
  color: white;
}

.description-h1 {
  letter-spacing: 1.2px;
}

input.search-input,
select.search-input {
  padding: 15px;
}

button.enter-keywords-btn {
  background: var(--primary-col);
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
button.enter-keywords-btn:hover {
  background: var(--primary-col);
}

select.type-select {
  width: 13rem;
}

button.toggle-advanced-search {
  border: none;
  background: transparent;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (min-width: 992px) {
  .max-w-lg-25 {
    max-width: 25%;
  }
}
