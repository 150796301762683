a{
  text-decoration: none;
}

.orgpic-parent{
  max-width: 100px;
  position: relative;
  height: fit-content;
  top: 0;
  left: 0;

}.orgpic-bg{
  position: relative;
  top: 0;
  left: 0;
  
}.orgpic-fg{
  width: 55px;
  position: absolute;
  opacity: 0.8;
  border-radius: 5px;
  padding: 0px;
  right: 0px;
  bottom: 0px;
}

.orgpic-work {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

#photo-upload{
  visibility: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: 2000;
}
#organiztionPic{
  visibility: hidden;
  height: 50px;
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: 2000;
  bottom: 0px;
  right: 0px;
  width: 55px;
}
.active{
  text-decoration: underline;
}
.text-underline{
  text-decoration: underline;
}
.fa-edit{
  cursor: pointer;
}
.slider-parent{
  position: relative;
}

.buble{
  position:absolute;
  top: -24px;
  top: -70px;
}
.pentagon {
  position: relative;
  width: 36px;
  border-width: 26px 0px 0px;
  border-style: solid;
  border-color: #103252 transparent;
}
.pentagon:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: 0px;
  left: 0px;
  border-width: 0 18px 20px;
  border-style: solid;
  border-color: transparent transparent #103252;
  transform: rotate(180deg);
}
.pentagon-text{
  position: absolute;
  color: rgb(236, 240, 37);
  top: -24px;
  left: 10px;
}

.no-resize{
  resize: none;
}
.fw-lite{
  font-weight:500;
}
.work-edit{
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: black;
  border: 1px solid #cccdce;
  border-radius: 0px 4px 0px 0px;
  border-top: none;
  font-size: 18px;
}
.input-description{
  border: none;
}
.org-url::-webkit-input-placeholder {
  color: rgb(189, 189, 190);
}
.show-btn-open{
  height: 25px;
  width: 100%;
  border: none;
  position: relative;
  color: lightslategrey;
  background-image: linear-gradient(to right, #E4EBF6, #F3F9FF);
}

.show-btn-close{
  height: 25px;
  width: 100%;
  border: none;
  position: relative;
  color: lightslategrey;
  background-image: linear-gradient(to right, #E4EBF6, #F3F9FF);
}

.opac-text{
  opacity: 0.3;
}
.fw-500{
  font-weight: 500;
}

.description-bg{
  background-color: #F5F5F5;
  width: 100%;
  height: 333px;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}

.add-details-btn{
  border: unset;
  border-bottom: 2px solid black;
  background: white;
  height: 26px;
}

.input-h-50{
  height: 50px;
}

.profile-modal-bg{
  background-color: #eee;
  margin: 20px;
  padding: 20px;
  border: 1px solid gray;
  border-radius: 10px;
}

.edu-card-header {
  font-size: 16px;
    font-weight: 600;
    color: #064B72;
    line-height: 1.2;
}
.edu-subheader {
  font-size: 14px;
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 3px;
}
.fs-18{
  font-size: 18px;
}
.fs-14{
  font-size: 14px;
}
.edu-img-border{
  border: 3px solid #d6d6d6;
  border-radius: 3px;
}

.work-details-margin {
  margin-top: 30px;
}
.fs-responsive{
  font-size: 16px;
}
.text-label-right{
  position: relative;
}
.contribution-label-left{
  position: relative;
}
.contribution-label-right{
  position: relative;
}
.load-more-exp-btn{
  background: #074A75;
}
.text-line-epipsis{
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Media query for desktop */
@media (min-width: 768px) {
  .work-details-margin {
    margin-top: 80px;
  }
  .fs-responsive{
    font-size: 14px;
  }
  .text-label-right{
    right: -26px;
  }
  .contribution-label-left{
    left: -56px;
  }
  .contribution-label-right{
    right:-56px;
  }
  .load-more-exp-btn{
    background: #D9EBFF;
  }
  .load-more-exp-btn:hover{
    background: #D9EBFF;
    opacity: 0.8;
  }
}

/* Media query for mobile */
@media (max-width: 767px) {
  .work-details-margin {
    margin-top: 28px;
  }
  .fs-responsive{
    font-size: 12px;
  }
  .text-label-right{
    right: -48px;
  }
  .contribution-label-left{
    left: -28px;
    width: 85px;
  }
  .contribution-label-right{
    right: -32px;
    width: 85px;
  }
  .add-details-btn{
    font-size: 13px;
  }
  .load-more-exp-btn{
    background: #074A75;
    color: white;
  }
  .load-more-exp-btn:hover{
    background: #074A75;
    color: white;
    opacity: 0.8;
  }
}

.card-border {
  border: none;
  border-radius: 12px;
  background-color: white;
  transition: 0.4s;
  padding: 5px 10px;
  box-shadow: 0px 1px 4px gray;
}

.delete-profile-btn{
  color: white;
  background-color: #B01314;
  width: 32px;
  font-size: 18px;
}

.add-skill-btn{
  background-color: white;
  width: 100%;
  border: 2px solid #c2c2c2;
  color: #064A74;
  font-size: 14px;
}

.message-box{
  z-index: 20;
  position: absolute;
  top: -55px;
  /* right: 0; */
  width: 95%;
  margin-left: 98%;
}
.message-box-text{
  position: absolute;
  margin-left: 10%;
  z-index: 21;
  top: -54px;
  font-size: 12px;
  padding: 14px 20px;
}
.bg-light{
  background-color: #FCFCFC;
}

.info-icon{
  height: 15px;
  width: 15px;
  display: inline-block;
  /* overflow: hidden; */
  border-radius: 50%;
}
.active-info-icon{
  background: #C3E1FF;
}

/* 
  Simple and fancy tooltip
*/
[data-tooltip] {
  --arrow-size: 5px;
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + var(--arrow-size));
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 8px 12px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  background-color: white;
  border: 2px solid #426B89;
  /* background-image: linear-gradient(30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44)); */
  /* box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2); */
  color: #426B89;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%,  calc(0px - var(--arrow-size))) scale(0.5);
}

.cursor-pointor{
  cursor: pointer;
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: '';
  border-style: solid;
  border-width: var(--arrow-size) var(--arrow-size) 0px var(--arrow-size); /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top;   /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, calc(0px - var(--arrow-size))) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it for the basic tooltip.

  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + var(--arrow-size));
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(calc(0px - var(--arrow-size)), 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: var(--arrow-size) 0px var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}



/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + var(--arrow-size));
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(var(--arrow-size), 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(var(--arrow-size), 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}



/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + var(--arrow-size));
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, var(--arrow-size)) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, var(--arrow-size)) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px var(--arrow-size) var(--arrow-size) var(--arrow-size);
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}