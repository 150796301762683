.blue-heading{
    font-size: 18px;
    color: darkblue;
}
.short-text{
    font-size: 13px;
}
.delete-education-btn{
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    color: white;
    background-color: #B01314;
    width: 35px;
    font-size: 19px;
}