.tocStyles {
  font-size: 20px;
  font-family: "Plus Jakarta Sans";
  line-height: 25.5px;
  letter-spacing: -0.17px;
}

.bgtoc {
  background-color: #edf6ff;
}

.bgShadow {
  box-shadow: 1px 0px 10px 0px #00000040;
}

.contentStyles {
  font-size: 16px;
  /* font-weight: 300; */
  line-height: 23.9px;
  color: #3e4348;
  height: 40px;
  font-family: "Plus Jakarta Sans";
}

.accordion90 {
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-100%);
}

.accordion0 {
  transform-origin: top left;
  transform: rotate(0deg);
}

/* .accordion-button {
    margin-left: 0 !important;
    margin-right: auto;
} */

.hideArrow:after {
  display: none;
}

#tableOfContents .accordion-button:after {
  order: -1;
  margin-left: 0;
  margin-right: 0.5em;
  transform: rotate(-90deg);
}

.side-dropdown {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-dropdown::-webkit-scrollbar {
  display: none;
}

.accordion-button:not(.collapsed):after {
  transform: rotate(0deg) scale(0.8) !important;
}

#tableOfContents .accordion-button:not(.collapsed) {
  background-color: white !important;
  /* background-color: #EDF6FF !important; */
}

#tableOfContentsHeading {
  letter-spacing: 1px;
}

.subContentStyles {
  /* font-family: 'Plus Jakarta Sans'; */
  font-size: 14px;
  line-height: 20px;
  color: #3e4348;
  letter-spacing: -0.17px;
  font-weight: 400;
}

.subHeadBtn {
  border: 0;
  margin: 0;
  padding: 0;
}

.subHeadBtn :hover {
  background-color: rgba(171, 212, 255, 1);
  border-width: 0.5px 0px 0.5px 0px;
  border-style: solid;
  border-color: #000000;
}

.side-toc-dropdown {
  width: 20rem;
}

.toc-close-icon {
  margin-left: 6px;
  width: 15px;
}
.toc-down-arrow {
  width: 8%;
}
.toc-subheadings {
  padding-left: 3rem;
}
.toc-subheadings:hover {
  background-color: #abd4ff;
}

@media (max-width: 767px) {
  #tableOfContentsAccordion > div {
    border: 0;
    left: -10px;
  }
  .side-toc-dropdown {
    width: 17.1rem;
  }
  .hideShadow {
    box-shadow: none !important;
  }
  .toc-close-icon {
    margin-left: -8px;
    width: auto;
  }
  .float-toc-heading {
    padding: 12px 14px;
  }
  .toc-down-arrow {
    width: unset;
    margin-right: 4px;
  }
  .accordion-button {
    padding: 12px 14px;
  }
  #tableOfContents .accordion-button:after {
    transform: rotate(-90deg) scale(0.8);
  }
  .toc-subheadings {
    padding-left: 2.7rem;
  }
}
