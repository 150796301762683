.search-terms-options{
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    gap: 1rem;
}

.search-term-option-inputs{
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.search-term-option-inputs > * {
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    background-color: lightgrey;
}

.search-term-option-tabs{
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 10px 0;
}

.search-term-option-tabs > button{
    padding: 5px 25px;
    width: 250px;
    border-radius: 4px;  
    border: none;
    color: black;
}