.test2Completion {
    height: 540px;
    width: 778px;
    background-color: white;
    margin-top: 50px;
    background: #FFFFFF;
    box-shadow: 10px 11px 10.9px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
}

.testCompletion h6 {
    margin: 20px;
    text-align: center;
}

.testCompletion Button {
    margin-top: 30px;
}