.add-section {
    border: none;
    border-radius: 12px;
    background-color: white;
    transition: 0.4s;
    box-shadow: 0px 1px 4px gray;
  }
  .add-section:hover {
    border: none;
    border-radius: 12px;
    background-color: rgb(243, 243, 243);
    box-shadow: 1px 2px 5px gray;
  }