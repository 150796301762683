select.sort-by {
  border: none;
  font-weight: 500;
  background: transparent;
  font-size: 17px;
  cursor: pointer;
}

button.pagination-btn {
  border: 1px solid #ddd;
  margin: 0.5rem;
  background: white;
}

button.pagination-btn:hover {
  background: #eee;
}

button.pagination-btn.active {
  background: #2baacc;
  color: white;
}

.shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.savedAdsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 8px;
  border-style: solid;
  border-width: 1.5px;
  border-color: var(--bs-border-color-translucent);
  -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.removeAdButtons {
  width: 130px;
  height: 40px;
  border-radius: 8px;
  border-style: none;
}

.confirmationDialogBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.confirmationDialogBox_buttonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.confirmationDialogBox_buttonsContainer_buttons {
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin: 20px;
  border-radius: 8px;
  background-color: white !important;
  border-color: var(--bs-border-color-translucent);
}
