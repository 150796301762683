.demo-editor{
  resize: vertical;
  max-height: 100%;
  height: 80vh;
}
  
  /* tooltip.css */
.image-upload-label {
  position: relative;
  display: inline-block;
}

.image-upload-label::after {
  content: attr(data-tip);
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 4px 8px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  font-family: sans-serif;
  color: lightgray;
  transition: visibility 0s, opacity 0.2s linear;
}

.image-upload-label:hover::after {
  visibility: visible;
  opacity: 1;
}

.rdw-local-image-wrapper:hover{
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
/* 
.rdw-image-wrapper{
  border: 1px solid rgba(128, 128, 128, 0.438);
  border-radius: 2px;
} */
