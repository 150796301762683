.bookmarked-articles-container {
  position: relative;
  box-shadow: 0px 4px 11px -2px rgba(0, 0, 0, 0.18);
  background: #d9d9d9;
}
.custom-overlay {
  background: linear-gradient(
    180deg,
    #fafafa 41.55%,
    rgba(215, 215, 215, 0) 142.45%
  );
}
.custom-bookmarked-articles {
  z-index: 100;
  margin-left: 0;
  margin-right: 0;
}
.custom-liked-articles {
  margin-left: 0;
  margin-right: 0;
}
.bookmarked-liked-articles {
  padding: 0px 14rem 2.2rem 4rem;
}
:target {
  scroll-margin-top: 200px;
}
.bookmarked-article-heading {
  color: var(--gray-900, #18181b);
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 62px;
}
.show-more-button-style {
  width: 177px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 1px solid #000;
  background: #fff;
  margin-top: 36px;
  margin-bottom: 49px;
}
.show-more-button-typography {
  color: #000;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 767px) {
  .bookmarked-liked-articles {
    padding: 0px 2rem 1rem 2rem;
  }
}
