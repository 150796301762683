.pagination-container-layout {
  display: flex;
  max-width: 353px;
  max-height: 65px;
  align-items: center;
  gap: 8px;
  flex-shrink: 1;
}
.pagination-button-layout {
  display: flex;
  width: 53px;
  height: 53px;
  padding: 6.625px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.pagination-button-style {
  border-radius: 20px;
  border: 1px solid #dfe3e8;
  background: #fafafa;
}
.pagination-button-typography {
  color: #000;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.pagination-number-layout {
  display: flex;
  width: 53px;
  height: 53px;
  padding: 16px 0px 17px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.pagination-current-style {
  border-radius: 20px;
  background: #ffa400;
  box-shadow: 2px 0px 6.4px 0px rgba(0, 0, 0, 0.16);
}
.pagination-button-disable {
  border-radius: 20px;
  opacity: 0.5;
  background: #919eab;
}
