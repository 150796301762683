.authorImgDiv {
  width: 48px;
  height: 52px;
}

.authorImgDiv > img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.authorName {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 2px;
}

.mobpara {
  font-size: 13px;
}

.comName {
  font-size: 14px;
}

@media (max-width: 767px) {
  .media {
    width: 457px;
    max-width: 100%;
  }

  .authorImgDiv {
    width: 48px;
  }
  .authorImgDiv > img {
    border-radius: 50%;
    width: 44px;
    height: 44px;
  }
}
