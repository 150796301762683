.irrelevantAdButtonContainer{
    padding-left: 0.8rem;
}
.savedAdButtonContainer{
    padding-left: 0.8rem;
}

@media screen and (max-width: 991px){
    .irrelevantAdButtonContainer{
        padding-left: 0.4rem;
    }
    .savedAdButtonContainer{
        padding-left: 0.4rem;
    }
  }
  