.tagstyle {
  padding: 0.2rem 1.2rem 0.2rem 1.2rem;
  margin-bottom: 24px;
  line-height: 32px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .tagstyle {
    padding: 0 0.5rem 0rem 0.5rem;
    margin-bottom: 12px;
    margin-top: 15px;
    font-size: 11px;
  }
}
