.application-rejection-page {
    height: auto;
    width: 1080px;
    background-color: white;
    margin: 50px 0px;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* transform: translate(-50%, -50%); */
}
.rejection-message{
    font-size: 18px;
}
.rejection-page-icon{
    width: 375px;
}
@media (max-width: 800px) {
    .rejection-message{
        font-size: 14px;
    }
    .application-rejection-page {
        height: auto;
        width: 80vw;
    }
    .rejection-page-icon{
        width: 80%;
        max-width: 360px;
    }
}