.chatgpt-main-container {
    min-height: 100vh;
  }
  
  .chatgpt-layout {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .topic-button {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    justify-content: center;
  }
  
  .heading-question-btn {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .heading-question-btn i {
    margin-bottom: 12px;
  }
  
  .child-relation-question {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .child-relation-question i {
    margin-bottom: 12px;
  }
  
  .question-tag {
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #1e3a8a;
  }
  
  .relation {
    width: fit-content;
    /* padding-left: 60px; */
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #1e3a8a;
  }
  
  .highlight-with-bg {
    border-radius: 15px;
    display: flex;
    gap: 0rem;
    width: fit-content;
    margin-left: -10px;
    font-size: 18px;
    color: #6b1e1e;
  }
  
  .highlight-heading {
    color: #510400;
    width: fit-content;
    padding-left: 30px;
  }
  
  .response-filtered-text {
    display: flex;
    gap: 1rem;
    width: fit-content;
    flex-wrap: wrap;
  }
  
  .filtered-text {
    margin: 2px;
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 16px;
    color: #6b1e1e;
  }
  
  .filtered-text > i {
    cursor: pointer;
  }
  
  .removeFilteredTextBtn {
    visibility: hidden;
  }
  
  .filtered-text:hover .removeFilteredTextBtn {
    visibility: visible;
  }

  .deleteHeadingBtn {
    visibility: hidden;
  }
  
  .highlight-heading:hover .deleteHeadingBtn {
    visibility: visible;
  }
  
  .filter-btn {
    border: none;
  }
  
  .addHeadingOrQuestion {
    display: none;
  }
  
  .child-relation-question:hover .addHeadingOrQuestion {
    display: block;
    position: absolute;
  }
  
  .heading-question-btn:hover .addHeadingOrQuestion {
    display: block;
    position: absolute;
  }
  
  .regenerateResponseBtn {
    margin-left: 27px;
    font-size: 1rem;
  }
  .regenerateResponseBtn button {
    height: 24px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .regenerateResponseBtn button i {
    margin: 0;
    font-size: 1rem;
  }
  
  .outline-container {
    background-color: rgba(164, 161, 161, 0.081);
    padding: 5px;
    border-radius: 10px;
  }
  
  .outline-question {
    color: #1e3a8a;
  }
  
  .outline-point {
    color: #6b1e1e;
  }
  
  .outline-sentence {
    color: black;
  }
  
  .text-maroon {
    color: maroon;
  }
  
  .row label {
    padding-left: 0;
    padding-right: 0;
  }
  