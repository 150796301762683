.saved-project-line{
    height: 92%;
    width: 3px;
    background-color: #C2C2C2;
    margin-top: 45px;
  }
  .delete-proj-btn{
    background: #B01314;
    color: white;
    padding: 8px;
    font-size: 22px;
    border-radius: 5px;
  }

.time-period-input{
  max-height: 50px;
}