.responseSelectionPopup {
  overflow: hidden;
  font-weight: 500;
}
.responseSelectionPopup > span:first-child {
  border-bottom: 1px solid #555;
}

.responseSelectionPopup > span:hover {
  background-color: #6c757d;
  color: white;
}
