:root {
  --primary-col: #ffa400;
}

body {
  font-family: "Poppins", sans-serif;
}

/* utils */
.font-semibold {
  font-weight: 500;
}

.notifications {
  position: fixed;
  z-index: 9999;
}

.notifications.top-right {
  right: 10px;
  top: 70px;
}
