.custom-rounded {
  border-radius: 25px; /* Adjust the value as needed for more or less roundness */
}

.card-image {
  border-radius: 25px 25px 0px 0px;
  height: 251px;
  object-fit: cover;
}

.course-footer {
  display: flex;
  max-height: 20px;
  margin-top: 38px;
}

.course-heading {
  color: #183762;
  max-height: 143px;
}

.course-heading h1 {
  padding-top: 48px;
  margin-left: 150px;
  padding-bottom: 48px;
  font-weight: bold;
  font-family: "Inter";
}

.courseTitle {
  font-size: 23;
  font-family: "Inter";
  margin-top: 27px;
}

.course-card {
  width: 407px;
  height: 458px;
  margin-bottom: 77px;
  margin-right: 0px;
}

.course-layer-icon {
  color: "blue";
  margin-left: 5px;
  margin-right: 5px;
  height: 21px;
  width: 20px;
}

.course-lesson {
  color: #053ba4;
  font-weight: bold;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.course-arrow-icon {
  margin-left: 50%;
  height: 16px;
  width: 28px;
}

.course-container {
  margin: 98px auto auto auto;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 1457px;
}


@media (max-width: 1330px) and (min-width: 998px) {
  .course-container {
    width: 917px;
    justify-content: space-between;
    margin-top: 97px;
  }
}

@media (max-width: 997px) {
  .course-container {
    width: 325px;
    justify-content: center;
    margin-top: 36px;
  }
  .course-card {
    width: 313px;
    height: 300px;
    margin-bottom: 40px;
  }
  .card-image {
    height: 150px;
    width:320;
    object-fit: cover;
  }
  .course-footer {
    display: flex;
    max-height: 20px;
    margin-top: 19px;
  }
  .course-layer-icon {
    color: "blue";
    margin-left: 10px;
    margin-right: 5px;
    height: 21px;
    width: 20px;
  }
  
  .course-lesson {
    color: #053ba4;
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .course-arrow-icon {
    margin-left: 40%;
    height: 16px;
    width: 28px;
  }
  .courseTitle {
    font-size: 15;
    font-family: "PlusJakartaSans";
    margin-top: 2px;
    margin-left:10px;
    max-height: 72px;
    overflow-y: hidden;
  }
  .course-heading h1{
    margin-left: 100px;
  }
}
