.carousel-image {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.carousel-control-next {
  top: 45%;
  right: 0%;
  bottom: unset;
  background: white;
  border: 1px solid lightgray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 40px;
  width: 40px;
}

.carousel-control-next-icon {
  height: 20px;
}

.carousel-control-prev {
  top: 45%;
  bottom: unset;
  background: white;
  border: 1px solid lightgray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 40px;
  width: 40px;
}

.carousel-control-prev-icon {
  height: 20px;
}
