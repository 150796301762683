.bg {
  background-color: #edf6ff;
}
.article-toc ol {
  padding-left: 2.5rem;
}
@media (max-width: 767px) {
  .article-toc ul {
    padding-left: 24px;
  }
  .article-toc ol {
    padding-left: 1rem;
  }
}
