.recommImg {
  width: 324px !important;
  height: 162px !important;
}

.tags {
  background-color: #d9eaea;
  font-weight: 300;
  text-align: center;
  padding: 3px 5px;
  margin-right: 3px;
}
.recommended-blog-mobile {
  margin-right: 36px;
  margin-bottom: 24px;
}
