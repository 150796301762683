.signin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 5000; 
  overflow-y: auto;
  backdrop-filter: blur(10px);
}
.signin-container {
  width: 50%;
  margin: auto;
  background: white;
}
.custom-latest-heading {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #18181b;
}
.custom-tagline-top {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 26.53px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.custom-tagline-bottom {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #848484;
}
.custom-subscribe-container {
  position: relative;
  max-width: 353px;
  height: 59px;
}
.custom-input {
  padding-right: 78px;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 64px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  color: #969393;
}
.custom-subscribe-button {
  width: 67px;
  height: 48.27px;
  border: none;
  border-radius: 64px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(180deg, #ffc878 0%, #ffa400 100%);
}

/* .blog-date {
  font-size: 14px;
  color: gray;
} */
/* .btn-tag {
  font-size: 15px;
  margin-left: 5px;
  border-radius: 20px;
  border: 1px solid black;
  background-color: #d7dadc;
} */
/* .author-details {
  font-size: small;
} */
/* .author-image {
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 8px;
} */
/* .btn-subscribe {
  font-size: 16px;
  border: none;
  color: white;
  background-color: #0099ff;
  border-radius: 25px;
  padding: 10px 14px;
} */

.custom-subscribe-email {
  padding-left: 32px;
  font-size: medium;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .custom-latest-heading {
    font-size: 34px;
    line-height: normal;
  }
  .custom-tagline-top {
    font-size: 22px;
  }
  .custom-tagline-bottom {
    font-size: 18px;
  }
  .custom-subscribe-container {
    width: 75%;
    padding-right: 10px;
  }
  .custom-subscribe-email {
    height: 32px;
    padding-left: 16px;
  }
  .custom-subscribe-button {
    height: 26px;
    width: 53px;
    top: 16px;
    right: 5px;
  }
  .custom-subscribe-arrow {
    position: relative;
    top: -7px;
    right: 0px;
    width: 22px;
  }
}

@media (max-width: 576px) {
  .custom-latest-heading {
    font-size: 28px;
    padding: 0px 16px 0px 16px;
  }
  .custom-subscribe-email {
    height: 28px;
    font-size: 13px;
    font-family: "Plus Jakarta Sans";
  }
  .custom-subscribe-button {
    height: 22px;
    width: 53px;
    top: 14px;
  }
  .custom-subscribe-arrow {
    position: relative;
    top: -10px;
    width: 20px;
  }
  .custom-tagline-top {
    font-size: 18px;
  }
  .custom-tagline-bottom {
    font-size: 13px;
    padding-right: 10px;
    line-height: 1.3em;
    margin-bottom: 1px;
  }
}

/* Media query for screens between 768px and 1023px */
/* @media (min-width: 768px) and (max-width: 1023px) {
  .author-details {
    font-size: large;
  }
  .author-image {
    object-fit: cover;
    width: 140px;
    height: 140px;
    border-radius: 8px;
  }
} */

/* Media query for screens larger than 1024px */
/* @media (min-width: 1024px) {
  .author-details {
    font-size: large;
  }
  .author-image {
    object-fit: cover;
    width: 140px;
    height: 140px;
    border-radius: 8px;
  }
} */
