.course-navigation-container {
   
}
.course-navigation-heading {
  background-color: #496eac;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


/* .section-completed-icon {
    width:  20px;
    height: 20px;
}
.course-section-details {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.accordion-section {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.accordion-header {
  padding: 1rem;
}
.accordion-header.open {
  background-color: #fffac5;
} */
