.verifyemail-container {
    max-width: 834px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
  }
  @media (max-width: 480px) {
    .verifyemail-container {
      margin-top: 10px;
   }
  }
  .verifyemail-container img {
    display: block;
    width: 280px;
    margin: auto;
  }
  @media (max-width: 480px) {
    .verifyemail-container img {
      width: 100px;
   }
  }
  .verifyemail-container .heading {
    float: left;
    width: 100%;
    font-size:50px;
    color: #e8e9eb;
    text-align: center;
  }
  @media (max-width: 480px) {
    .verifyemail-container .heading {
      font-size: 65px;
   }
  }
  .verifyemail-container .error-body {
    float: left;
    width: 100%;
    margin-top: 10px;
    padding-left: 50px;
  }
  @media (max-width: 480px) {
    .verifyemail-container .error-body {
      padding-left: 0px;
   }
  }
  .verifyemail-container .error-body p {
    float: left;
    width: 100%;
    font-size: 16px;
    color: #000;
  }
  .message{
    font-size: 25px;
    font-weight: bold;
  }
  