.site-link {
  font-size: 15px;
  color: #1a0dab;
  text-decoration: none;
}

.site-link-small {
  font-size: 14px;
}

.site-link:hover {
  text-decoration: underline;
  color: #1a0dab;
}

.site-link-description {
  font-size: 13px;
}
