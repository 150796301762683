.borderStyle {
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #f2f2f2;
}

.interact-img {
  width: 20px;
}
.like {
  /* width: 16px; */
  /* border border-0 text-decoration-none bg-transparent */
}
.btn[disabled] {
  opacity: 0.1;
  cursor: progress;
}

@media (max-width: 767px) {
  .interactStyles {
    height: 36px;
  }
  .interact-img {
    width: 20px;
  }
}
