.currentUpvoteCountText {
  opacity: 1;
  color: black;
  font-weight: 300;
  font-size: 1rem;
  padding-left: 6px;
  padding-right: 6px;
}

.buttonContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.verticleLine {
  border-left: 1px solid gray;
  height: 20px;
  margin-right: 6px;
  margin-bottom: 3px;
}
