.priceItem-title {
  text-decoration: none;
  color: #1a0dab;
}

.priceItem-title:hover {
  text-decoration: underline;
  color: #1a0dab;
}

.priceItem-description {
  font-size: 15px
}

.priceItem-price{
  font-size: 15px
}
