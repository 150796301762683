.forgot-password-page {
    height: 460px;
    width: 472px;
    background-color: white;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 90px;
    padding-top: 60px;
    margin: 50px 0px;
}

body {
    background-color: #F5F5F5
}

.forgot-password-container {
    height: 442px;
    width: 442px;
    background-color: white;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 25px;
    padding-bottom: 20px;
}

.opt-exceed {
    width: 442px;
    background-color: #FFEBE8;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #CC0000;
}