.article-container p {
  font-size: 19px;
}
/* .article-container [class^="h"] {
  font-weight: 800;
} */
@media (max-width: 767px) {
  .article-container p {
    font-size: 18px;
  }
}
