.newsletter-card {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/images/dummy_newsletter-background.jpg");
  background-size: cover;
  background-position: center;
  color: white;
}

.enter-your-email {
  padding: 15px;
  max-width: 45rem;
}

.submit-email {
  background: var(--primary-col);
}
