h4 {
    font-weight: 500;
    color: #292929;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.38px;

}

@media (max-width: 767px) {
    h4 {
        font-size: 19px;
        line-height: 24px;
        margin: 1rem 0 1rem 0;
    }


}