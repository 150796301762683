.course-dashboard-container {
  background: #fff;
  box-shadow: -3px 3px 14px -2px rgba(0, 0, 0, 0.3);
}
.dashboard-navitem {
  cursor: pointer;
  color: #7b7676;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  user-select: none;
}
.dashboard-navitem.selected {
  color: #496eac;
}
