.search-term-analysis-table{
    display: flex;
    flex-direction: column;
    margin: 15px;
    align-items: center;
}

.table-head span{
    color: grey;
    font-size: 12px;
}

.search-terms-filter{
    width: 70vw;
    display: flex;
    align-items: flex-start;
}

.search-terms-filter > input{
    border-radius: 4px;
    border-width: 0.1px;
    width: 170px;
    padding: 0;
}

.search-terms-filter > img{
    width: 20px;
    margin: 7px;
}

.sort-tab{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.sort-tab > *{
    width: 20px;
}

.search-term-in-table{
    width: 32%;
    word-wrap: break-word;
}

.show-more-btn{
    border: none;
    margin: 5px;
    background-color: rgb(63, 172, 203);
    padding: 10px;
    border-radius: 5px;
    width: 48vw;
}

.empty-box{
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
}

.table-head{
    text-align: center;
}

.show-more-btn{
    border: none;
    margin: 5px;
    background-color: rgb(63, 172, 203);
    padding: 10px;
    border-radius: 5px;
    width: 64vw;
}

.remove-children-Search-term{
    padding: 2px 6px;
    font-size: small;
    font-weight: bold;
}

.remove-children-Search-term:hover{
    background-color: black;
    color: white;
}

.add-children-Search-term{
    padding: 2px 6px;
    font-size: small;
    font-weight: bold;
}

/* .add-children-Search-term:hover{
    background-color: black;
    color: white;
} */

