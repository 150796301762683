.paymentPreferencePage {
  width: 1306px;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 10px 11px 10.9px rgba(0, 0, 0, 0.13);
  font-family: "Poppins";
  padding: 3.5rem;
}

.paymentPreferenceSubheading p {
  font-style: normal;
  font-weight: 400;
  color: #585858;
  font-size: 1.25rem;
  margin: 0;
  line-height: 2.3em;
}

.paymentPreferenceLecture p,
.paymentPreferenceBottom p {
  font-size: 1.25rem;
  margin: 0;
  line-height: 2.3em;
}
.buttons button {
  width: 18.5rem;
  height: 4.3rem;
  margin-top: 3rem;
  margin-bottom: 3.2rem;
  font-weight: 600;
}
.money-button {
    margin-right: 0.7rem;
}
.lecture-button {
    margin-left: 0.7rem;
}

@media (max-height: 800px) {
  .paymentPreferencePage {
    width: 70vw;
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 10px 11px 10.9px rgba(0, 0, 0, 0.13);
    /* padding: 2.4rem; */
  }
  .paymentPreferenceSubheading p,
  .paymentPreferenceLecture p,
  .paymentPreferenceBottom p {
    font-size: 1rem;
    line-height: 1.6em;
  }
  .buttons button {
    width: 14.5rem;
    height: 3.3rem;
    margin-top: 1.8rem;
    margin-bottom: 2rem;
  }
}
