.cannot-continue-page {
    height: auto;
    width: 1080px;
    background-color: white;
    margin: 50px 0px;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* transform: translate(-50%, -50%); */
}
.sad-page-icon{
    width: 400px;
}
.cannot-continue-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width: 800px) {
    
    .sad-page-icon{
        width: 250px;
    }
    .cannot-continue-message{
        font-size: 16px;
    }
    .cannot-continue-page {
        height: auto;
        width: 80vw;
    }
}