.accordion-header {
  white-space: initial;
}

.accordion {
  white-space: break-spaces;
}

.dropdown-centered > * {
  width: fit-content;
}

.outline-container {
  width: 1%;
  overflow: hidden;
  height: 500px;
  transition: width 0.3s ease;
  color: #f9fbfd;
  position: absolute;
  left: 1.3rem;
  height: 70vh;
  z-index: 4;
}

.outline-container:hover {
  width: 20%;
  overflow-y: auto;
  scroll-behavior: smooth;
  color: black;
}

.expand {
  width: 20%;
  overflow-y: auto;
  scroll-behavior: smooth;
  color: black;
}

.vertical-outline {
  position: absolute;
  left: -10px;
  padding: 0;
  margin: 0;
  margin-top: 3%;
  font-size: small;
  transform: rotate(-90deg);
  color: black !important;
}

.response-container {
  width: 98%;
}

.outline-container > p {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  font-size: 14px;
}

.outline-container > p:hover {
  font-weight: 500;
  cursor: pointer;
}

.responses-text {
  font-size: 15px;
}

.parsed-html-container * img {
  max-width: 100% !important;
}

.parsed-html-container * div {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}
.parsed-html-container * p {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.custom-dropdown-item {
  width: 250px;
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
  word-wrap: break-word;
}

.custom-dropdown-item:focus,
.custom-dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef;
}

.btns-inside-accordion {
  position: static;
  margin: 0;
  padding: 1px 10px;
  background-color: #c5c8d5;
  border: none;
  border-radius: 4px;
  color: black;
}

.dropdown-article-title {
  width: 100%;
}
.dropdown-article-titles {
  width: 417px;
}
@media (max-width: 767px) {
  .dropdown-article-titles {
    width: 54vw;
  }
}

.article-select {
  background-color: "#3EACCA";
}
.pctask-loader {
  position: relative;
  color: #0c63e4;
  left: 50%;
  z-index: 100;
  top: 5%;
}
.error-pc-task {
  background-color: lightcoral important;
}
@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
