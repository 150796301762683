.authorName2 {
  font-size: 23px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 2px;
}

.author-bio {
  font-size: 14px;
}

.mobpara2 {
  font-size: 14px;
}

.comName2 {
  font-size: 14px;
}

@media (max-width: 767px) {
  .media {
    width: 457px;
    max-width: 100%;
  }
  .authorName2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 2px;
  }

  .authorImgDiv2 {
    width: 63px;
  }
}
