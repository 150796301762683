i.fa-bolt {
    color: var(--primary-col);
  }
  
  .social-sign {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    background: transparent;
    margin-right: 0.8rem;
    font-weight: 500;
    padding: 12px 8px;
    margin-bottom: 8px;
    font-size: 14px;
    text-decoration: none;
    color: black;
  }
  
  .social-sign:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px 0px;
    color: black;
  }
  
  img.social-sign-logo {
    height: 1.5rem;
  }
  
  .strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    color: #aaa;
    font-size: 14px;
  }
  
  .strike-span {
    position: relative;
    display: inline-block;
    text-transform: lowercase;
  }
  
  .strike-span:before,
  .strike-span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #ddd;
  }
  
  .strike-span:before {
    right: 100%;
    margin-right: 15px;
  }
  
  .strike-span:after {
    left: 100%;
    margin-left: 15px;
  }
  
  .formError {
    font-size: 14px;
  }
  
  .formInput {
    padding: 14px;
  }
  
  .passwordVisible {
    right: 0.71rem;
    top: 3.15rem;
    background: white;
    color: #666;
    cursor: pointer;
  }
  
  .forgot-password {
    font-weight: 500;
    font-size: 15px;
  }
  
  .sign-button {
    border: none;
    color: white;
    font-weight: bold;
  }
  