.custom-blog-container {
  /* t r b l  */
  padding: 35px 0px 30px 45px;
  background: #fafafa;
  filter: drop-shadow(0px 3.31648px 8px rgba(0, 0, 0, 0.12));
  border-radius: 9.94943px;
}

.custom-post-author {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #18181b;
}
.custom-post-readtime {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #18181b;
}
.custom-post-title {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 26.5318px;
  line-height: 39px;
}
.custom-post-title a {
  color: #053ba4;
}
.custom-post-content {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #18181b;
}
.custom-post-tags {
  border-radius: 14px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11.27px;
  line-height: 20px;
  color: #18181b;
}
.custom-post-image-container {
  position: absolute;
  right: -100px;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 238px;
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07));
}
.bi-dot {
  color: darkgray;
}
.post-dislike-icon,
.post-like-icon {
  width: 22px;
}
.author-pic-container {
  height: 38px;
  width: 38px;
}
.author-profile-pic {
  width: 38px;
  height: 38px;
}
.post-upvote-count {
  font-size: 16px;
}
.downvote-btn {
  margin-left: 28px;
}
.save-post-icon {
  height: 28px;
}
.unsave-post-icon {
  height: 28px;
  padding: 4px 4px 0px 4px;
}
/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .custom-post-image-container {
    width: 180px;
    height: 143px;
    right: -52px;
    /* filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07)); */
  }
  .custom-post-author {
    font-size: 12px;
    font-weight: 700;
    font-family: unset;
    line-height: 1.4em;
  }
  .custom-post-readtime {
    font-size: 12px;
    font-family: unset;
    line-height: 1.4em;
  }
  .custom-blog-container {
    padding: 12px 0px 5px 14px;
    border-radius: 18px;
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.2));
  }
  .custom-post-title {
    min-height: 117px;
  }
}

@media (max-width: 576px) {
  .custom-post-image-container {
    width: 114px;
    height: 94px;
    right: -52px;
    /* filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07)); */
  }

  .custom-post-title {
    min-height: 65px;
    font-size: 14px;
    line-height: normal;
  }
  .author-pic-container {
    height: 32px;
    width: 36px;
  }
  .custom-post-author {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.4em;
  }
  .custom-post-readtime {
    font-size: 11px;
    line-height: 1.4em;
  }
  .custom-post-tags {
    font-size: 8px;
    height: 18px;
  }
  .post-tag-value {
    position: relative;
    top: -2px;
  }
  .downvote-btn {
    margin-left: 14px;
  }
  .post-dislike-icon,
  .post-like-icon {
    width: 12px;
  }
  .save-post-icon {
    position: relative;
    right: -16vw;
    height: 16px;
  }
  .unsave-post-icon {
    position: relative;
    right: -16vw;
    height: 16px;
    padding: 4px 4px 0px 4px;
  }
  .author-profile-pic {
    width: 28px;
    height: 28px;
  }
  .post-upvote-count {
    font-size: 9px;
  }
}

/* Media query for screens smaller than 400px */
@media (max-width: 400px) {
  .custom-post-title {
    font-size: 14px;
    line-height: normal;
  }
  .custom-post-author {
    /* font-size: 9px; */
    font-weight: 700;
  }
  .custom-post-readtime {
    font-size: 9px;
  }
  .custom-post-tags {
    font-size: 8px;
  }
}

/* Media query for screens between 768px and 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
  .custom-post-image-container {
    width: 260px;
    height: 207px;
    right: -80px;
    /* filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07)); */
  }
  .custom-blog-container {
    padding: 16px 0px 12px 24px;
  }
}

/* Media query for screens larger than 1024px */
@media (min-width: 1024px) {
  .custom-post-image-container {
    width: 300px;
    height: 238px;
    /* filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07)); */
  }
}
