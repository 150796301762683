
.profilepic-parent{
    width: 100%;
    position: relative;
  
  }.profilepic-bg{
    width: 100%;
    aspect-ratio: 1;
    max-width: 330px;
    max-height: 330px;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;
    
  }.profilepic-fg{
    position: absolute;
    opacity: 0.8;
    border-radius: 5px;
    padding: 5px;
    top: 50%;
    left: 28%;
    transform: translate(-14%, -50%);
    
  }
  .profilepic-location{
    border: none;
    border-bottom: 1px solid gray;
  }
  .socialmedia-links{
    list-style-type: none;
  }
  .socialmedia-links > li{
    margin-bottom: 8px;
  }