.main-container {
  min-height: 90vh;
  margin: 2rem 0;
}
.email-verification-container {
  width: 52rem;
  box-shadow: 0px 0px 10.9px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  padding: 4.2rem;
  margin-top: 2rem;
}
.email-icon {
  display: flex;
  justify-content: center;
  padding-bottom: 1.4rem;
}
.verify-email-heading {
  color: #272727;
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-size: 1.875em;
  line-height: 43px;
  text-align: center;
}
.verify-email-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 34px;
  margin: 0;
  color: #585858;
  text-align: center;
}
.verify-email {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 39px;
  color: #272727;
  text-align: center;
  padding: 1rem 0;
}
.resend-button {
  width: 16rem;
  height: 3.4rem;
  color: white;
  text-align: center;
  background: linear-gradient(180deg, #507eff 0%, #3970ff 100%);
  border: 1px solid #3a71ff;
  border-radius: 4px;
}
.resend-button:disabled {
  background: linear-gradient(
    180deg,
    rgba(80, 126, 255, 0.5) 0%,
    rgba(57, 112, 255, 0.5) 100%
  );
}
.mail-not-received {
  padding-top: 3rem;
}

/* css media query */
@media (max-width: 1024px) {
  .email-verification-container {
    width: 80%;
    padding: 1rem 1rem 3rem 1rem;
    margin: 2rem 0;
  }
  .email-icon {
    padding: 1rem 0;
  }
  .email-icon img {
    width: 128px;
    height: 128px;
  }
  .verify-email-heading {
    font-size: 1.75em;
    line-height: 40px;
  }
  .verify-email-text {
    font-size: 1em;
  }
  .verify-email {
    font-size: 1em;
  }
  .resend-button {
    width: 11rem;
    height: 3rem;
  }
  .mail-not-received {
    padding-top: 1rem;
  }
  .main-container {
    height: fit-content;
    margin: 0;
  }
}
