
.ck-blockquote {
  margin-bottom: 16px;
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.ck-pre-block {
  background: #f5f5f5;
  border: 1px solid #c4c4c4;
  padding: 10px;
  overflow: auto;
  position: relative;    
  border-radius: 2px;
  color: #353535;
}
.ck-pre-block::after {
    background: #757575;
    color: #fff;
    font-size: 10px;
    font-family: Helvetica, Arial;
    line-height: 16px;
    padding: 4px;
    right: 10px;
    top: -1px;
    white-space: nowrap;
    /* content: "Plain text"; */
    position: absolute;
}
.ck-pre-block code {
  background: unset;
  border-radius: 0;
  padding: 0;
  text-wrap: wrap;
}