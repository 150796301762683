.article-issues {
  border: 1px solid #ccc;
  margin: 10px 0px;
  padding: 10px;
  background-color: #f8f9fa;
  font-family: monospace;
}

.article-issues .header {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
}

.article-issues .icon {
  margin-right: 5px;
}

.article-issues .collapsed .content {
  display: none;
}

.article-issues .property {
  margin-left: 20px;
}

.article-issues .key {
  font-weight: bold;
  margin-right: 5px;
}
