.ck-editor__editable_inline {
    min-height: 90vh;
    height: 90vh;
}
.ck-powered-by{
    display: none;
}
.ck-font-size-dropdown > .ck-dropdown__panel{
    max-height: 60vh;
    overflow: auto;
}