footer {
  background: var(--primary-col);
  position: relative;
  z-index: 5;
}

.company-info {
  max-width: 32rem;
}

.company-logo {
  height: 5rem;
  /* border-radius: 100%; */
}

.company-desc {
  line-height: 2;
  font-size: 15px;
}

.page-link {
  font-size: 18px;
  text-decoration: none;
  color: black;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.contact-item {
  margin-bottom: 0.5rem;
  font-size: 17px;
}

@media screen and (max-width: 520px) {
  .company-logo {
    height: 4rem;
    /* border-radius: 100%; */
  }
}