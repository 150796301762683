.rating {
  padding-left: 5px;
  color: #70757a;
  font-size: 14px;
}

.rating-star {
  color: #fbbc04;
  font-size: 14px;
}
