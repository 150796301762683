.filteredPointHeadingInModal {
  font-weight: 500;
  margin-bottom: 5px;
}

.filteredPointsContainerInModal {
  gap: 8px 20px;
}

.unCheckedFilteredPointInModal {
  text-decoration: line-through;
}

.childQuestionPreviewInModal {
  font-size: 1.3rem;
}
