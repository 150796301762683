.divider-hitbox {
  cursor: col-resize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15px;
  width: 5px;
  min-height: 100vh;
}
  
.rightPane {
  flex: 2;
}
  
.splitView {
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.left-pane-default-width{
  width: 50%;
}