.test3Completion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 460px;
    width: 778px;
    background-color: white;
    margin-top: 50px;
    background: #FFFFFF;
    box-shadow: 10px 11px 10.9px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
}
