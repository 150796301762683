.main-container {
  min-height: 90vh;
}
.email-verified-container {
  width: 52rem;
  padding: 2.4rem 1.4rem;
  box-shadow: 0px 0px 10.9px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
}
.email-verified-heading {
  color: #272727;
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  font-size: 1.875em;
  line-height: 43px;
  text-align: center;
}
.email-verified-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 34px;
  color: #585858;
  text-align: center;
}
.text-1 {
  margin-top: 2rem;
}
.text-2 {
  margin: 2rem 4rem 0 4rem;
}

/* css media query */
@media (min-width: 768px) and (max-width: 1024px) {
  .email-verified-container {
    width: 80%;
    padding: 1rem;
    margin: 1rem 0;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .email-verified-container {
    width: 80%;
    padding: 1rem;
  }
}

@media (max-width: 500px) {
  .email-verified-container {
    width: 80%;
    padding: 1rem;
    margin: 3rem 0;
  }
  .email-verified-heading {
    font-size: 1.75em;
    line-height: 40px;
  }
  .verify-email-text {
    font-size: 1em;
  }
  .main-container {
    height: fit-content;
  }
  .text-2 {
    margin: 1rem;
  }
}
