.ad-page-container{
    display: flex;
    justify-content: center;
}

.ad-details-layout{
    width: 80vw;
    margin: 15px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.landing-page-img > img{
    width: 100%;
}

.landing-page-img{
    padding: 5px;
    border: 0.1px solid black;
}

.ad-details-ad-card{
    width: 410px;
}

/* .ad-details-ad-card > *{
    height: fit-content;
} */

.ad-org-card{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.display-ad-org-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.landing-page-headline{
    margin-top: 15px;
    color: black;
    font-size: 18px;
    font-weight: 500;
}
