.facebook-ad {
  border-radius: 8px;
}

.card-body {
  font-family: "Roboto", Arial;
}

.ad-header {
  padding: 12px;
}

.logo-url {
  width: 31px;
  height: 31px;
  border-radius: 100%;
}

.organization-name {
  font-size: 14px;
  text-decoration: none;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  line-height: 18px;
}

.organization-name:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.85);
}

.sponsored-indicator {
  color: rgba(0, 0, 0, 0.55);
  font-size: 12px;
  font-weight: 600;
}

.primary-text {
  font-size: 14px;
  padding: 0px 12px;
  margin-bottom: 12px;
}
