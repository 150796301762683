.gpt-prompt {
  width: 100%;
  box-sizing: border-box;
}

.gpt-response {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid gray;
}
.gpt-response:focus {
  outline: none;
  border: 1px solid gray;
}
.response-spinner {
  bottom: -10px;
  margin-right: 10px;
  position: relative;
}

.response-error {
  border: 1px solid red;
}
