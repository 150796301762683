.demo-editor {
  padding-left: 10px;
  padding-right: 10px;
}
.parent-container {
  position: relative;
}

.title-container {
  position: relative;
  z-index: 10;
}

.tags-container {
  position: relative;
  z-index: 9;
}
.author-container {
  position: relative;
  z-index: 8;
}

.editor-container {
  position: relative;
  z-index: 1;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: aliceblue;
  z-index: 19;
  opacity: 0.8;
}
.local-datetime {
  padding: 5px;
  border: 2px solid grey;
  border-radius: 5px;
}

.post-tooltip {
  z-index: 3;
}

.show-container {
  z-index: 3;
  position: relative;
  left: -30px;
  top: 36px;
}

.post-tooltip.fixed {
  position: fixed;
  top: 0;
}

.showtooltip {
  position: relative;
  display: inline-block;
  top: -5px;
}

.showtooltip .showtooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  border: 1px solid lightblue;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 3;
  top: 5%;
  left: 170%;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
  height: 60px;
}

.showtooltip:hover .showtooltiptext {
  visibility: visible;
  opacity: 1;
}

.add-post {
  border: none;
  border-radius: 12px;
  background-color: white;
  transition: 0.4s;
  box-shadow: 0px 1px 4px gray;
}
.add-post:hover {
  border: none;
  border-radius: 12px;
  background-color: rgb(243, 243, 243);
  box-shadow: 1px 2px 5px gray;
}
.text-small {
  font-size: 14px;
}
.post-outline-btn {
  border: none;
  background-color: #eee;
  border-radius: 10px;
  color: black;
  padding: 5px 10px;
}
.post-outline-btn:hover {
  box-shadow: 2px 2px 5px gray;
  background-color: #eee;
  color: black;
}

/** Upload thumbnail styles */

.thumbnail-image-container {
  width: 300px;
  height: 238px;
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07));
}
.preview-image-container {
  width: 200px;
  height: 160px;
  margin-top: 10px;
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.07));
}
.custom-post-image {
  object-fit: cover;
}
.thumbnail-preview {
  border-radius: 13px;
}

.mymodel-container {
  position: relative;
  z-index: 9;
}
.mymodal {
  position: absolute;
  background-color: white;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.2));
  border-radius: 4px;
  width: 220px;
}
.mymodal-tabs > button {
  border: none;
  background: none;
  border-bottom: 3px solid #eee;
}
.mymodal-tabs > .active-btn-tab {
  border-bottom: 3px solid blue;
}
.mymodal-close-button {
  border: none;
}
.mymodal-close-button:hover {
  background-color: rgb(255, 104, 104);
  color: white;
}
.edit-thumbnail {
  position: relative;
  background-color: #eee;
  width: fit-content;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.edit-thumbnail:hover {
  box-shadow: 2px 2px 5px gray;
}
.post-outline {
  overflow: auto;
  resize: vertical;
  height: 100vh;
}

/* Tooltip container */
.mytooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.mytooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  width: 160px;
  top: 22px;
  right: 5%;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.mytooltip:hover .tooltiptext {
  visibility: visible;
}
.small-spinner {
  width: 24px;
  height: 24px;
  bottom: -5px;
  position: relative;
  margin-right: 5px;
}
.title-load-spinner {
  width: 24px;
  height: 24px;
  position: relative;
  top: -32px;
  left: -32px;
  margin-right: 5px;
}
