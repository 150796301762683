#navbarTogglerDemo02 {
  z-index: 5;
  background: #ffa400;
  position: relative;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  right: 0;
  left: auto;
  margin-top: var(--bs-dropdown-spacer);
}

.custom-navlist {
  margin-right: auto;
}
@media (max-width: 576px) {
  .navbar-logo-img {
    width: 250px;
    padding-left: 16px;
  }
}
@media (min-width: 1200px) {
  .custom-navlist {
    padding-right: 160px;
  }
}
@media (max-width: 1200px) {
  #navbarTogglerDemo02 {
    margin-right: auto;
  }
}
