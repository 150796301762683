.stages-connector{
    position: absolute;
    width:56%;
    margin-top: 14px;
    margin-left: 8.7px
}

.stages-block{
    width: 140px;
    text-align: center;
    line-height: 1.1;
}
.test-message{
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    margin: 0px 20px;
}
.start-test-btn{
    text-decoration: none;
    padding: 11px 48px;
    margin-top: 14px;
    background: #EFF8FF;
    border: 1px solid #3DA9E8;
    border-radius: 11px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #0F76B3;
}
.stage-btn-item{
    border: 1px solid #eee;
    padding: 10px 11px;
    background: #eee;
    border-radius: 6px;
    box-shadow: -3px 4px 6px #cccccc;
    cursor: pointer;
}
.acknowledge-modal-body{
    width: 70%;
    position: fixed;
    z-index: 40;
    box-shadow: gray 1px 1px 6px;
    min-height: 300px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}
.applicant-question{
    font-size: 22px;
    padding: 0px 10px;
    max-width: 900px;
}

@media (max-width: 767px) {
    .stages-connector{
        width: 48%;
        margin-top: 14px;
        margin-left: 3px;
    }
    
.stage-btn-item{
    font-size: 14px;
    width: 120px;
}
.acknowledge-modal-body{
    top: 25%;
    left: 5%;
    transform: translate(0%, 0%) !important;
}
.applicant-question{
    font-size: 16px;
}
.question-option-btn{
    font-size: 12px;
    padding: 8px 30px;
    margin: 16px 0px;
}
.test-message{
    font-size: 18px;
}
.start-test-btn{
    font-size: 18px;
}
}