nav.navbar {
  background-color: var(--primary-col);
  font-weight: 500;
  height: 86px;
}

.navbar-brand-img {
  height: 64px;
  /* border-radius: 100%; */
}
.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* .brand-name {
  font-size: 22px;
  font-weight: 500;
} */

.nav-item {
  display: inline-block;
}
.nav-link {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #18181b;
}
.nav-link:hover {
  color: #18181b;
}
.active {
  font-weight: 600;
}

.loggedInUserFullName {
  color: rgba(0, 0, 0, 0.7);
  background: transparent;
  border: none;
}
.custom-signin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 44px;
  background: #fbfbfb;
  border-radius: 10px;
}
.custom-signin-text {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000000;
}
