.ck-editor__editable_inline:not(.ck-comment__input *) {
  max-height: 300px !important;
  min-height: auto;
  overflow-y: auto;
}
.add-outline {
  width: 75px;
  height: 44px;
  margin-right: 0px;
  margin-left: auto;
  text-align: center;
  padding: 10px;
  border: none;
  border-radius: 14px;
  background-color: white;
  transition: 0.4s;
  box-shadow: 0px 1px 4px gray;
}
.add-outline:hover {
  border: none;
  border-radius: 14px;
  background-color: rgb(243, 243, 243);
  box-shadow: 1px 2px 5px gray;
}
.plus-icon-container {
  position: relative;
}
.options-container {
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;
}
.option {
  padding: 8px 16px;
  cursor: pointer;
}
.option:hover {
  background-color: #f5f5f5;
}
.fa-plus {
  font-size: 18px;
}
