.carousel-image {
  width: 100%;
  height: 415px;
  max-height: 415px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.carousel-image-div {
  max-height: 415px;
}

.ad-footer {
  padding: 12px;
  padding-top: 5px;
}

.normalized-url {
  color: #606770;
  text-decoration: none;
  font-size: 12px;
}

.normalized-url:hover {
  color: #606770;
  text-decoration: underline;
}

.headline {
  color: #1c1e21;
  text-decoration: none;
  font-size: 15.5px;
}

.headline:hover {
  color: #1c1e21;
  text-decoration: underline;
}

.description {
  color: #606770;
  text-decoration: none;
  font-size: 12.5px;
  line-height: 17px;
}

.description:hover {
  color: #606770;
  text-decoration: underline;
}

a.cta-button {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-size: 14px;
  border: none;
  padding: 8px 13px;
  border-radius: 5px;
}
a.cta-button:hover {
  background: rgba(0, 0, 0, 0.1);
}
