.org-card{
    width: 45%;
    word-wrap: break-word;
    padding: 10px;
    height: 100%;
}

.display-org-card{
    width: 100%;
    word-wrap: break-word;
    padding: 10px;
    height: 100%;
}

.org-card-img-name{
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 5px;
    padding: 2px;
    margin-bottom: 30px;
}

.org-card-img-name > img{
    width: 100px;
    border-radius: 50%;
}

/* .org-description{
    height: 150px;
    overflow-x: hidden;
} */

/* .read-more-btn{
    width: 98%;
    display: flex;
    justify-content: flex-end;
} */

.read-more-btn > button{
    border-radius: 4px;
}