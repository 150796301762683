.h1 {
  font-size: 40px;
  color: "#292929";
  line-height: 52px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .media {
    width: 457px;
    max-width: 100%;
  }

  .h1 {
    font-size: 27px;

    line-height: 38px;
    /* letter-spacing: -0.46px; */
  }
}
