.post-data-border {
  border: 1px solid #acacac;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.post-content {
  overflow-y: auto;
  resize: vertical;
  height: 80vh;
}
