.carousel-image-div {
  /* max-height: 415px; */
  height: fit-content;
}

.carousel-image {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
