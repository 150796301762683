.font-sans-serif {
  font-family: sans-serif;
}

/* #carouselExampleCaptions {
  min-height: 400px;
} */
/* #carouselExampleCaptions:hover {
  overflow: hidden;
} */

/* .carousel-item {
  transition: transform 1s ease-in-out;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 1s ease-in-out;
} */

.text-for-feature {
  font-family: "Inter";
  font-style: normal;
  font-size: 22px;
  line-height: 28px;
  font-weight: normal;
  text-align: center;
  color: #52525b;
  margin-top: 16px;
  margin-bottom: 14px;
}
/* .mobile-btn-feature {
  font-size: 18px;
  margin-top: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  padding: 4px 8px;
} */
/* .active-feature-mobile {
  background-color: rgb(232, 232, 232);
  color: black;
} */

.newsfeed-slide {
  position: relative;
  box-shadow: 0px 4px 11px -2px rgba(0, 0, 0, 0.18);
  background: #d9d9d9;
}
.custom-overlay {
  z-index: 2;
  background: linear-gradient(
    180deg,
    #fafafa 41.55%,
    rgba(215, 215, 215, 0) 142.45%
  );
}
.main-para-heading {
  font-family: "Plus Jakarta Sans";
  font-size: 48px;
  font-weight: 800;
  line-height: 62px;
  background: linear-gradient(
    90.8deg,
    #ff00b8 41.81%,
    #004bdd 75.37%,
    #8f00ff 96.35%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.active-feature {
  font-weight: normal;
  background-color: rgba(217, 217, 217, 0.52);
  cursor: pointer;
  padding: 0px 10px 0px 10px;
  text-align: center;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.17);
  border-radius: 29px;
}
.btn-carosel {
  font-size: 20px;
  background-color: black;
}
.carousel-img-container {
  padding-left: 36px;
  padding-right: 36px;
}
/* .btn-carosel:hover {
  background-color: #c9c9c9;
  color: rgb(0, 0, 0);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.custom-download-now {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}
.custom-chrome-extension {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #d9d9d9;
}
.extension-info {
  padding-bottom: 30px;
}
.newsfeed-carousel-img {
  object-fit: cover;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 13px;
}
.carousel-arrow-left,
.carousel-arrow-right {
  height: 12px;
}
.sub-main-heading {
  font-size: 32px;
}
/* Media query for screens smaller than 768px */
@media (max-width: 576px) {
  .text-for-feature {
    font-size: 14px;
  }
  .main-para-heading {
    font-size: 32px;
    line-height: 1.5em;
  }
  .sub-main-heading {
    font-size: 18px;
    line-height: 1em;
  }
  .supercharge-icon {
    height: 24px;
  }
  .custom-overlay {
    z-index: 2;
    background: linear-gradient(
      176deg,
      #f3f3f3 41.55%,
      rgb(178 178 190 / 5%) 190.45%
    );
    box-shadow: 0px 10px white;
    border-bottom: 1.7px solid black;
  }
  .custom-download-now {
    font-weight: 300;
    margin-right: 6px;
    font-size: 15px;
  }
  .custom-chrome-extension {
    font-size: 10px;
  }
  .carousel-arrow-left,
  .carousel-arrow-right {
    height: 8px;
  }
}

/* Media query for screens between 768px and 1023px */
/* @media (min-width: 768px) and (max-width: 1023px) {
  .newsfeed-slide {
    padding: 30px;
  }
  .newsfeed-carousel {
    width: 100%;
  }
} */

/* Media query for screens larger than 1024px */
@media (min-width: 1024px) {
  .newsfeed-slide {
    padding: 5px 0px;
  }
  /* .newsfeed-carousel {
    width: 97%;
    height: auto;
  } */
}
@media (min-width: 1024px) and (min-height: 620px) {
  .newsfeed-slide {
    padding: 30px 0px;
  }
  /* .newsfeed-carousel {
    width: 100%;
    height: auto;
  } */
}
